import { fixBody, getCookie, setCookie, unfixBody } from '../main.js';

const bar = document.querySelector('.cookie-bar');
const popup = document.querySelector('.cookie-popup');

/*********************
 * DEFAULT CONSENT
 *********************/

document.addEventListener('DOMContentLoaded', (e) => {
    setDefaultConsent();
});

function setDefaultConsent()
{
    const analyticalCookies = getCookie('analytical-cookies');
    const advertisingCookies = getCookie('advertising-cookies');

    if (analyticalCookies && advertisingCookies) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });
    } else if (analyticalCookies) {
        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'granted'
        });
    } else if (advertisingCookies) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'denied'
        });
    }
}

/*********************
 * SETTINGS BUTTON
 *********************/

const settingsButton = document.querySelector('.cookie-settings-btn');
if (settingsButton) {
    settingsButton.addEventListener('click', (e) => {
        e.preventDefault();
        bar.classList.add('hidden');
        popup.classList.add('active');
        fixBody('popup-active');
    });
}

/*********************
 * BAR ACCEPT BUTTON
 *********************/

const barAcceptButton = document.querySelector('.cookie-bar-accept-btn');
if (barAcceptButton) {
    barAcceptButton.addEventListener('click', (e) => {
        e.preventDefault();
        bar.classList.add('hidden');
        setCookie('functional-cookies', 'accepted', 365);
        setCookie('analytical-cookies', 'accepted', 365);
        setCookie('advertising-cookies', 'accepted', 365);
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    });
}

/*********************
 * SETTINGS ACCEPT BUTTON
 *********************/

const settingsAcceptButton = document.querySelector('.cookie-settings-accept-btn');
if (settingsAcceptButton) {
    settingsAcceptButton.addEventListener('click', (e) => {
        e.preventDefault();
        const analyticalInput = document.getElementById('analytical-cookies');
        const advertisingInput = document.getElementById('advertising-cookies');

        popup.classList.remove('active');
        unfixBody('popup-active');
        setCookie('functional-cookies', 'accepted', 365);

        if (analyticalInput.checked) {
            setCookie('analytical-cookies', 'accepted', 365);
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });
        } else {
            setCookie('analytical-cookies', 'denied', 365);
        }

        if (advertisingInput.checked) {
            setCookie('advertising-cookies', 'accepted', 365);
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
            });
        } else {
            setCookie('advertising-cookies', 'denied', 365);
        }
    });
}